<template>
  <div>
    <div class="mb-2">
      <h1 class="h3 mb-3 text-gray-800">Closed Discussions</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <table class="table table-responsive-sm border-bottom">
          <tbody>
            <tr v-for="(topic, i) in topics" :key="`discussion-${i}`">
              <td width="10%">
                <router-link
                  :to="{
                    name: 'moderator.discussions.show',
                    params: { id: topic.id },
                  }"
                >
                  <img
                    :src="topic.author.avatar"
                    :alt="topic.title"
                    :class="`avatar ${loading ? 'skeleton' : ''}`"
                    style="height: 40px"
                  />
                </router-link>
              </td>
              <td class="border-right">
                <router-link
                  :to="{
                    name: 'moderator.discussions.show',
                    params: { id: topic.id },
                  }"
                >
                  <h5
                    :class="`mb-0 ${loading ? 'skeleton skeleton-text' : ''}`"
                  >
                    {{ topic.title }}
                  </h5>
                  <p class="mb-0">
                    <small>{{ $localizer.parse(topic.date) }}</small>
                  </p>
                </router-link>
              </td>
              <td width="10%">
                <router-link
                  :to="{
                    name: 'moderator.discussions.show',
                    params: { id: topic.id },
                  }"
                >
                  <p class="mb-0 text-black">Replies</p>
                  <p class="mb-0">
                    {{ topic.comments }}
                  </p>
                </router-link>
              </td>
            </tr>
            <tr v-if="topics.length == 0">
              <td>
                <div class="alert alert-warning">
                  There is no data to display
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      topics: [{author: {}}, {author: {}}, {author: {}}, {author: {}}, {author: {}}],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/moderator/discussions?status=closed").then((response) => {
        this.topics = response.data.topics;
        this.loading = false;
      });
    },
  },
};
</script>
